import React, { Component } from 'react'
import { Fragment } from 'react'
import { Card, Col, Container, Row, Button } from 'react-bootstrap'
import '../../asset/css/Bootstrap.min.css'
import '../RecentProject/RecentProject.css'
import P1 from '../../asset/image/clinic.jpg'
import { Link } from 'react-router-dom'

import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'


export class RecentProject extends Component {
    constructor(){
        super();
        this.state={
            data:[]
        }
    }
    componentDidMount()
    {
      RestApi.GetRequest(AppUrl.HomeProjects).then(result=>{
        this.setState({data:result})
      });
    }
    render() {
        const List = this.state.data;
        const View = List.map(List=>(
            <Col lg={4} md={6} sm={12} >
                            <Card className='projectCard'>
                                <Card.Img variant="top" src={P1} />
                                <Card.Body>
                                    <Card.Title className='projectName'>{List.project_name}</Card.Title>
                                    <Card.Text className='projectDescription'>{List.project_discription}</Card.Text>
                                    <Link to={"/ProjectDetailsPage/"+List.id}> <Button variant="primary">More</Button></Link>
                                </Card.Body>
                            </Card>
                        </Col>
         ));
        return (
            <Fragment>
                <Container className='text-center'>
                    <h1 className='serviceMainTitle text-center'>آخر المشاريع</h1>
                    <div className='bottom'></div>
                    <Row>
                        {View}
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default RecentProject
