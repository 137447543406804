import React, {Component, Fragment } from "react";
import AppRouter from '../src/Router/AppRouter'

import {  BrowserRouter
} from "react-router-dom";

function App (){

    return (
      <div>
        
<BrowserRouter>
        <AppRouter/>
        </BrowserRouter>
        </div>
    )
  }

export default App


