import React, { Component } from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import '../Team/Team.css';
import '../../asset/css/Bootstrap.min.css'

import av from '../../asset/image/images.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'


export class Team extends Component {
    constructor(){
        super();
        this.state={
            data:[]
        }
    }
    componentDidMount()
    {
      RestApi.GetRequest(AppUrl.Team).then(result=>{
        this.setState({data:result})
      });
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        let $imgPath = 'images/';
        const List = this.state.data;
        if(List){
        const View = List.map(List=>(
        <div>
            <Row className='text-center justify-content-center'>
                <Col lg={6} md={6} sm={12}>
                <img src={$imgPath+List.team_img} className='circleImg' />
                    <h4 className='Name'>{List.team_title}</h4>
                    <p className='Description'>{List.team_description}</p>
                </Col>
            </Row>
        </div>
        ));
        return (
            <Fragment>
                <Container fluid={true} className='Bgcolor text-center p-5'>
                    <h1 className='serviceMainTitle Name text-center p-2 text-white'>فريقنا</h1>
                    <div className='bottom1'></div>
                    <Slider {...settings}>
                       {View}
                    </Slider>
                </Container>
            </Fragment>
        )
        }
    }
}

export default Team
