import React, { Component, Fragment } from 'react'
import TopPage from './TopPage'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import TopNavigation from '../components/TopNavigation/TopNavigation'

export class ContactPage extends Component {

     componentDidMount() {
          window.scroll(0, 0)
     }


     render() {
          return (
               <Fragment>
                    <TopNavigation />
                    <TopPage pageTitle="اتصل بنا | Contact US" />
                    <Contact />
                    <Footer />
               </Fragment>
          )
     }
}

export default ContactPage




