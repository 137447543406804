import React, { Component, Fragment } from 'react'
import TopPage from './TopPage'
import About from '../components/About/About'
import Footer from '../components/Footer/Footer'
import AboutDescreption from '../components/AboutDescreption/AboutDescreption'
import TopNavigation from '../components/TopNavigation/TopNavigation'
export class AboutPage extends Component {

     componentDidMount() {
          window.scroll(0, 0)
     }

     render() {
          return (
               <Fragment>
                    <TopNavigation />
                    <TopPage pageTitle="من نحن | About Us" />
                    {/*<About />*/}
                    <AboutDescreption />
                    <Footer />
               </Fragment>
          )
     }
}

export default AboutPage
