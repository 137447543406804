import React, { Component, Fragment } from 'react'

import { Col, Container, Row, Button } from 'react-bootstrap'

import g from '../../asset/image/projectDetails.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'


export class ProjectDetails extends Component {
     constructor(props){
          super();
          this.state={
               MyProjectID:props.id,
               project_name:'',
               project_description:'',
               project_features:'',
               live_preview:''
          }
     }
     componentDidMount(){
          RestApi.GetRequest(AppUrl.ProjectDetails+this.state.MyProjectID).then(result=>{
               this.setState({
                    project_name:result[0]['project_name'],
                    project_description:result[0]['project_description'],
                    project_features:result[0]['project_features'],
                    live_preview:result[0]['live_preview']
               });
     })
}
     render() {
          return (
             <Fragment>
             <Container className="mt-5">
             <Row>
                  <Col lg={6} md={6} sm={12} className="mt-5">

                       <img src={g} />

                  </Col>


                  <Col lg={6} md={6} sm={12} className="mt-5">
                       <div >
                            <h1> {this.state.project_name} </h1>
                            <p >
                                {this.project_description}
                            </p>

                            <p>
                                 <FontAwesomeIcon icon={faCheckSquare} />
                                 {this.state.project_features}
                            </p>

                            <Button variant="info" href="#"> {this.live_preview} </Button>

                       </div>

                  </Col>


             </Row>
        </Container>

             
             
             
             </Fragment>
          )
     }
}

export default ProjectDetails
