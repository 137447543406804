import React, { Component, Fragment } from 'react'
import '../../asset/css/custom.css'
import { Col, Container, Row ,Button} from 'react-bootstrap'
import '../../asset/css/Bootstrap.min.css'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'


export class TopBanner extends Component {
  constructor()
  {
    super();
    this.state={
      title:"",
      subtitle:""
    }
  }
  componentDidMount()
  {
    RestApi.GetRequest(AppUrl.Home).then(result=>{
      this.setState({title:result[0]['home_title'],subtitle:result[0]['home_subtitle']})
    });
  }

  render() {
    return (
    <Fragment>
        <Container className="topFixedBanner p-0" fluid={true}>
            <div className="topBanneroverly">
              <Container className='topContent text-center'  >
                <Row>
                  <Col >
                    <h1 className='topTitle' > {this.state.title} </h1> 
                    <h4 className='topSubTitle' >{this.state.subtitle} </h4>
                    {/*<Button variant="primary">More</Button>*/}
                  </Col>
                </Row>
              </Container>
            </div>
            
        </Container>
    </Fragment>
    )
  }
}

export default TopBanner
