import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import TopPage from './TopPage'
import Footer from '../components/Footer/Footer'
import AllServices from '../components/AllServices/AllServices'

import Contact from '../components/Contact/Contact'

export class ServicePage extends Component {
     componentDidMount() {
          window.scroll(0, 0)
     }

     render() {
          return (
               <Fragment>
                    <TopNavigation />
                    <TopPage pageTitle="الخدمات | Services" />
                    <AllServices />
                    <Footer />
               </Fragment>
          )
     }
}

export default ServicePage
