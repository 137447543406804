import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row, Button } from 'react-bootstrap'
import P1 from '../../asset/image/clinic.jpg'
import '../AllProject/AllProject.css'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'
import { Link } from 'react-router-dom'

export class AllProject extends Component {
    constructor(){
        super();
        this.state={
            data:[]
        }
    }
    componentDidMount()
    {
      RestApi.GetRequest(AppUrl.AllProjects).then(result=>{
        this.setState({data:result})
      });
    }
    render() {
        const List = this.state.data;
        //if(!List){
        const View = List?.map(List=>(
        <Col lg={4} md={6} sm={12} >
        <Card className='projectCard'>
            <Card.Img variant="top" src={P1} />
            <Card.Body>
                <Card.Title className='projectName'>{List.project_name}</Card.Title>
                <Card.Text className='projectDescription'>{List.project_discription}</Card.Text>                
                <Link to={"/ProjectDetailsPage/"+List.id}> <Button variant="primary">التفاصيل ...</Button></Link>
            </Card.Body>
        </Card>
    </Col>
          ));
        return (
            <Fragment>
                <Container className='text-center'>
                    <h1 className='serviceMainTitle text-center'>المشاريع</h1>
                    <div className='bottom'></div>
                    <Row>
                        {View}
                    </Row>
                </Container>
            </Fragment>
        )
        //}
    }
}

export default AllProject
