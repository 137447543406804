import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faMap } from '@fortawesome/free-regular-svg-icons'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'

export class Contact extends Component {
    sendContact()
    {
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let message = document.getElementById("message").value;

        let jsonObject = {name:name,email:email,message:message}
        RestApi.PostRequest(AppUrl.Contact,JSON.stringify(jsonObject)).then(result=>{
            alert(result);
        }).catch(error=>{
            alert("Error");
        })
    }
    constructor(){
        super();
        this.state={
          about:"",
          our_mission:"",
          our_vission:""
        }
    }
    componentDidMount()
    {
      RestApi.GetRequest(AppUrl.About).then(result=>{
        this.setState({
             about:result[0]["about"],
             our_mission:result[0]["our_mission"],
             our_vission:result[0]["our_vission"],
        })
      });
    }
    render() {
        return (
            <Fragment>
                <Container>
                <h1 className='serviceMainTitle text-center'>اتصل بنا</h1>
                    <div className='bottom'></div>
                <h2 className='serviceMainTitle'>نحن نتطلع دائمًا للتواصل معكم والإجابة على استفساراتكم. لا تترددوا في التواصل معنا
</h2>    
                    <Row>
                          {/*<Col lg={6} md={6} sm={12}>
                            <h1 className='serviceName'>Discuss Now</h1>
                            <p>
                                <FontAwesomeIcon icon={faMap} /> Adress: fffffffffffffffffffffffffff <br />
                                <FontAwesomeIcon icon={faEnvelope} /> Email: dddddddddddd <br />
                                <FontAwesomeIcon icon={faPhone} /> Phone: +905318252702
                            </p>
        </Col>*/}
                        <Col lg={6} md={6} sm={12}>
                            <h1 className='serviceName'></h1>
                            <Form>
                                <Form.Group>
                                    <Form.Label></Form.Label>
                                    <Form.Control id='name' type="text" placeholder="الاسم" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label></Form.Label>
                                    <Form.Control id='email' type="email" placeholder="الايميل" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>اكتب رسالتك هنا</Form.Label>
                                    <Form.Control id='message' as="textarea" placeholder="الرسالة" />
                                </Form.Group>
                                <br />
                                <Button variant="primary" onClick={this.sendContact} type="submit">
                                    ارسال
                                </Button>
                            </Form>
                        </Col>
                      
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default Contact
