import axios from 'axios';

class RestApi {
  static GetRequest = (getUrl)=>{
    return axios.get(getUrl).then(respons=>{
        return respons.data;
    }).catch(error=>{
        return null;
    });
  }
  static PostRequest = (postUrl,postJson)=>{
    let config={
      headers:{
        'Content-type':'application/x-www-form-urlencoded'
      }
    }
    return axios.post(postUrl,postJson,config).then(respons=>{
        return respons.data;
    }).catch(error=>{
        return null;
    });
  }
}

export default RestApi
