import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Footer/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faMap } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
//import { fa faPhone } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import TUITLOGO from '../../asset/image/TUITLOGO.png'
import '../../asset/css/Bootstrap.min.css'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'
import parse from 'html-react-parser';



export class Footer extends Component {
  constructor(){
    super();
    this.state={
      facebook:"",
      youtube:"",
      linkedin:"",
      twitter:"",
      instegram:"",
      address:"",
      email:"",
      phone:""
    }
}
componentDidMount()
{
  RestApi.GetRequest(AppUrl.Footer).then(result=>{
    this.setState({
      facebook:result[0]["facebook"],
      youtube:result[0]["youtube"],
      linkedin:result[0]["linkedin"],
      twitter:result[0]["twitter"],
      instegram:result[0]["instegram"],
      address:result[0]["address"],
      email:result[0]["email"],
      phone:result[0]["phone"]
    })
  });
}
  render() {
    return (
      <Fragment>
      <Container className='footerSection' fluid={true}>
      <Row>
          <Col lg={4} md={6} sm={12} className="text-center p-5" >
              <h4 className='footerName'>تابعنا | Follow us</h4>
              <div className='social-container'>
              <a className='social iconcolor' href={this.state.facebook} ><FontAwesomeIcon icon={faFacebook}   size='2x'/></a>
              <a className='social iconcolor' href={this.state.youtube} ><FontAwesomeIcon icon={faYoutube}   size='2x'/></a>
              <a className='social iconcolor' href={this.state.linkedin} ><FontAwesomeIcon icon={faLinkedin}   size='2x'/></a>
              <a className='social iconcolor' href={this.state.twitter}><FontAwesomeIcon icon={faTwitter}   size='2x' /></a>
              <a className='social iconcolor' href={this.state.instegram}><FontAwesomeIcon icon={faInstagram}   size='2x' /></a>
              </div>
          </Col>
          <Col lg={4} md={6} sm={12} className='p-5 text-justify'>
              <h4 className='footerName'>العنوان | Adress</h4>
              <p className='footerDescription'>
             <FontAwesomeIcon icon={faMap} />&nbsp;&nbsp;{parse(this.state.address)}<br/><br/>
              <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;{this.state.email}<br/><br/>
              <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;{this.state.phone}
              </p>
          </Col>
          {/*<Col lg={4} md={6} sm={12} className='p-5 text-justify'>
              {/*<h4 className='footerName'> شركة النهضة التقنية لتكنولوجيا المعالومات</h4>
              <h4 className='footerName'>Teknik Upgrading for Information Technology</h4>*/}
             <Col lg={4} md={6} sm={12} className='p-0 text-center'>
              <img src={TUITLOGO} height={300} width={300} clas/>                        
            </Col>
      </Row>
      <Row>
      <Col className="text-center p-1" >
      {/*<div  className="hrCopyRight"></div>*/}
              <h1 className='footerName text-center'>النهضة التقنية | جميع الحقوق محفوظة 2024&copy;</h1>
      </Col>
      
      </Row>
  </Container>
  </Fragment>
    )
  }
}

export default Footer
