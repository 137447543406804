import React, { Component, Fragment } from 'react'
//import c1 from '../../asset/image/CourseDetails.jpg'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import '../CourseDetails/CourseDetails.css'
import '../../asset/css/Bootstrap.min.css'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'
import parse from 'html-react-parser';

export class CourseDetails extends Component {
     constructor(props){
          super();
          this.state={
               MyCourseID:props.id,
               LongTitle : '',	
               LongDescription : '',	
               TtotalDuration : '',
               TotalLecture : '',
               TotalStudent : '',
               Categories : '',
               Tags : '',
               Instructor : '',
               SkillAll : '',
               VideoUrl : '',
               SmallImg  : '',
          }

          }
          componentDidMount(){
               RestApi.GetRequest(AppUrl.CourseDetails+this.state.MyCourseID).then(result=>{
                    this.setState({
                         LongTitle : result[0]['long_title'],	
                         LongDescription : result[0]['long_description'],		
                         TtotalDuration : result[0]['total_duration'],	
                         TotalLecture : result[0]['total_lecture'],	
                         TotalStudent : result[0]['total_student'],	
                         Categories : result[0]['categories'],	
                         Tags : result[0]['tags'],	
                         Instructor : result[0]['instructor'],	
                         SkillAll : result[0]['skill_all'],
                         VideoUrl : result[0]['video_student'],
                         SmallImg : result[0]['small_img']
                    });
          })
     }
     
     render() {
          let $imgPath = '../images/';
          /*let LongTitle = "";	
          let LongDescription = "";	
          let TtotalDuration = "";
          let TotalLecture = "";
          let TotalStudent = "";
          let Categories = "";
          let Tags = "";
          let Instructor = "";
          let SkillAll = "";
          let videoUrl = "";
          let SmallImg = "";

          let Array = this.props.courseAllData;
          if(Array.length !=0){
               LongTitle = Array[0]['long_title'];	
               LongDescription = Array[0]['long_description'];		
               TtotalDuration = Array[0]['total_duration'];	
               TotalLecture = Array[0]['total_lecture'];	
               TotalStudent = Array[0]['total_student'];	
               Categories = Array[0]['categories'];	
               Tags = Array[0]['tags'];	
               Instructor = Array[0]['instructor'];	
               SkillAll = Array[0]['skill_all'];	
               videoUrl = Array[0]['video_student'];	
               SmallImg = Array[0]['small_img'];	
          }*/

          /*componentDidMount() {
               window.scroll(0, 0)
               RestApi.GetRequest(AppUrl.CourseDetails+this.state.MyCourseId).then(result=>{
               this.setState({CourseData:result});
               })
          }*/
          return (
               <Fragment>
                    <Container className="mt-5" >
                         <Row>
                              <Col lg={8} md={6} sm={12} >
                                   <h1>{parse(this.state.LongTitle)}</h1>
                                   <img className="courseImg" src={$imgPath+this.state.SmallImg}/>

                                   <p>{parse(this.state.LongDescription)}</p>


                              </Col>

                              <Col lg={4} md={6} sm={12}>
                                   <div className="box_feature">
                                        <h4 >Course Features</h4>
                                        <hr />
                                        <ul>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faUser} /> <span> Enrolled :</span>{this.state.TotalStudent}</li>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faClock} /> <span>Duration :</span>{this.state.TtotalDuration}</li>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faClipboard} /> <span>Lectures :</span> {this.state.TotalLecture}</li>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faClone} /> <span>Categories:</span>{this.state.Categories}</li>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faTags} /> <span>Tags:</span>{this.state.Tags}</li>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} /> <span>Instructor:</span>{this.state.Instructor}</li>
                                        </ul>
                                        <div className="price-d text-center" >
                                             <h5>Price:<span>$54.00</span></h5>
                                             <Button >ENROLL COURSE</Button>

                                        </div>
                                   </div>




                              </Col>
                         </Row>
                    </Container>

                    <br></br>  <br></br>

                    <Container>
                         <Row>
                              <Col lg={6} md={6} sm={12}>

                                   <div className="box_feature" >
                                        <h1 > Skill You Need  </h1>
                                        <hr />
                                        <ul>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} /> Metus interdum metus</li>
                                             <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} /> Ligula cur maecenas</li>

                                             <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} /> Metus interdum metus</li>

                                             <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} />Ligula cur maecenass</li>

                                             <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} /> Metus interdum metus</li>

                                        </ul>
                                   </div>
                              </Col>


                              <Col lg={6} md={6} sm={12}>

                                  {/* <Player src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4">
                                        <BigPlayButton position="center" />
          </Player>*/}
                              </Col>

                         </Row>
                    </Container>
















               </Fragment>
          )
     }
}

export default CourseDetails
