import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import TopPage from './TopPage'
import Footer from '../components/Footer/Footer'
import ProjectDetails from '../components/ProjectDetails/ProjectDetails'
import { useParams } from 'react-router-dom';

 function ProjectDetailsFunc() {
     let { ProjectId } = useParams();
     //return ProjectId;
     return <ProjectDetails id={ProjectId} />
   }


export class ProjectDetailsPage extends Component {
     render() {
          
          return (
               <Fragment>
                    <TopNavigation />
                    <TopPage pageTitle="تفاصيل المشروع | Project Details" />
                    <ProjectDetailsFunc />
                    <Footer />
               </Fragment>
          )
     }
}

export default ProjectDetailsPage
