import React, { Component, Fragment } from 'react'
import { Container, Navbar , Nav,NavDropdown } from 'react-bootstrap'
import '../TopNavigation/TopNavigation.css'
import Logo from '../../asset/image/logow.png'
//import LogoScroll from '../../asset/image/logoScroll.png'
import LogoScroll from '../../asset/image/logob.png'
import { NavLink } from 'react-router-dom'

export class TopNavigation extends Component {
    constructor()
    {
        super();
        this.state ={
            navBarTitle:'navTitle',
            logo:[Logo],
            NavBarBackColor:'navBackground',
            NavBarItem:'navItem',
            variant:'dark'
        }
    }
    Scroll=()=>{
        if(window.scrollY>100)
        {
            this.setState({navBarTitle:'navTitleScroll',logo:[LogoScroll],NavBarBackColor:'navBackgroundScroll',NavBarItem:'navItemScroll',variant:'light'})
        }
        else
        {
            this.setState({navBarTitle:'navTitle', logo:[Logo],NavBarBackColor:'navBackground',NavBarItem:'navItem',variant:'dark'})
        }
    }
    componentDidMount()
    {
        window.addEventListener('scroll',this.Scroll);
    }
  render() {
    return (
      <Fragment>
        <Navbar fixed='top' collapseOnSelect  expand="lg" variant={this.state.variant} className={this.state.NavBarBackColor}>
            <Navbar.Brand className={this.state.navBarTitle} href="#home"><img src={this.state.logo} width={40} height={40}  />&nbsp;
            النهضة التقنية | TUIT
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link><NavLink to="/" style={({ isActive,}) => {return {color: isActive ? "#f0ec2c":"#ffffff",};}} className={this.state.NavBarItem}  >الرئيسية</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/About" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >من نحن</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/AllServices" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >الخدمات</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/AllCourses" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >الكورسات</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/AllProject" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >المشاريع</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Contact" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >اتصل بنا</NavLink></Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
      </Fragment>/*
      <Navbar  collapseOnSelect expand="lg" bg="dark" variant="dark" className='space '>
      */
     /*
      <Fragment>
      <Navbar collapseOnSelect expand="lg" fixed='top' variant={this.state.variant} className={this.state.NavBarBackColor}>
      <Navbar.Brand className={this.state.navBarTitle} href="#home"><img src={this.state.logo} width={40} height={40}  />&nbsp;
            النهضة التقنية | TUIT
            </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link><NavLink to="/" style={({ isActive,}) => {return {color: isActive ? "#f0ec2c":"#ffffff",};}} className={this.state.NavBarItem}  >الرئيسية</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/About" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >من نحن</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/AllServices" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >الخدمات</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/AllCourses" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >الكورسات</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/AllProject" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >المشاريع</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Contact" style={({ isActive}) => {return {color: isActive ? "#f0ec2c":"#ffffff"};}} className={this.state.NavBarItem} >اتصل بنا</NavLink></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      
    </Navbar>
    </Fragment>
    */
      
    )
  }
}

export default TopNavigation
