import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import TopPage from './TopPage'
import Footer from '../components/Footer/Footer'
import CourseDetails from '../components/CourseDetails/CourseDetails'
import { useParams } from 'react-router-dom';

 function CourseDetailsFunc() {
     let { CourseId } = useParams();
     //return ProjectId;
     return <CourseDetails id={CourseId} />
   }


export class CourseDetailsPage extends Component {
     render() {
          return (
               <Fragment>
                    <TopNavigation />
                    <TopPage pageTitle="تفاصيل الكورس | Course Details" />
                    <CourseDetailsFunc />
                    <Footer />
               </Fragment>
          )
     }
}

export default CourseDetailsPage
