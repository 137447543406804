import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Courses/Courses.css'
import '../../asset/css/Bootstrap.min.css'
import C1 from '../../asset/image/course.jpg'
import { Link } from 'react-router-dom'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'
import parse from 'html-react-parser';

export class Courses extends Component {
    constructor(){
        super();
        this.state={
            data:[]
        }
    }
    componentDidMount()
    {
      RestApi.GetRequest(AppUrl.HomeCourses).then(result=>{
        this.setState({data:result})
      });
    }
    render() {
        let $imgPath = 'images/';
        const List = this.state.data;
        const View = List.map(List=>(
        <Col lg={6} md={6} sm={12} className='p-2' >
        <Row>
            <Col lg={6} md={6} sm={12} className="imgColumn">
                <img className='courseImg' src={$imgPath+List.small_img} />
            </Col>
            <Col lg={6} md={6} sm={12}>
                <h4 className='serviceName text-justify'>{parse(List.short_title)}</h4>
                <p className='serviceDescription text-justify'>{parse(List.short_description)}
                </p>
                <Link className='courseViewMore' to={'/CourseDetailsPage/'+List.id}>التفاصيل ...</Link>
            </Col>
        </Row>
    </Col>
     ));
        return (
            <Fragment>
                <Container className='text-center'>
                    <h1 className='serviceMainTitle text-center'>الكورسات</h1>
                    <div className='bottom'></div>
                    <Row>
                        {View}
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default Courses
