import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'
import parse from 'html-react-parser';
import '../AboutDescreption/AboutDescreption.css'
//import Team from '../components/Team/Team';
import Team from '../Team/Team' 

export class AboutDescreption extends Component {
     constructor(){
          super();
          this.state={
            about:"",
            our_mission:"",
            our_vission:""
          }
      }
      componentDidMount()
      {
        RestApi.GetRequest(AppUrl.About).then(result=>{
          this.setState({
               about:result[0]["about"],
               our_mission:result[0]["our_mission"],
               our_vission:result[0]["our_vission"],
          })
        });
      }
     render() {
          return (
               <Fragment>
                    <Container className="mt-5">
                         <Row>
                              <Col sm={12} lg={12} md={12}>
                                   <h1>من نحن | About US</h1>
                                   <hr />
                                   <p>{parse(this.state.about)}</p>
                                   <br></br>
                                   <h1 >هدفنا | Our Mission</h1>
                                   <hr />
                                   <p>{parse(this.state.our_mission)}</p>
                                   <h1 >رؤيتنا | Our Vission</h1>
                                   <hr />
                                   <p>{parse(this.state.our_vission)}</p>

                              </Col>

                         </Row>
                         <Row>
                              <Col sm={12} lg={12} md={12}>
                                  
                              <Team />
                              </Col>

                         </Row>
                        
                    </Container>



               </Fragment>

          )
     }
}

export default AboutDescreption
