
class AppUrl{
    static BaseApi='larCMS/api';
    static AllServices=this.BaseApi+'/AllServices';
    static HomeServices=this.BaseApi+'/HomeServices';
    static AllProjects=this.BaseApi+'/AllProjects';
    static HomeProjects=this.BaseApi+'/HomeProjects';
    static ProjectDetails=this.BaseApi+'/ProjectDetails/';
    static AllCourses=this.BaseApi+'/AllCourses';
    static HomeCourses=this.BaseApi+'/HomeCourses';
    static CourseDetails=this.BaseApi+'/CourseDetails/';
    static Footer=this.BaseApi+'/Footer';
    static Chart=this.BaseApi+'/Chart';
    static Team=this.BaseApi+'/Team';
    static About=this.BaseApi+'/About';
    static Home=this.BaseApi+'/Home';
    static Contact=this.BaseApi+'/Contact';
}
export default AppUrl

